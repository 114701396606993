import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Link } from '../util/Links'
import { SearchIconRed } from '../../widgets/Icons'
import classnames from 'classnames'
import './SearchMenu.scss'
import { ErrorPlaceholder, withErrorBoundary } from '../util/ErrorBoundaries'
import { connect } from 'kea'
import application from '../../../kea/application'
import format from 'date-fns/format'
import track from 'react-tracking'
import WP from '../../../lib/WP'
import tuumaOn from '../../../assets/ui/tuuma-on.png'
import tuumaOff from '../../../assets/ui/tuuma-off.png'
import tuumaMobileOn from '../../../assets/ui/tuuma-mobile-on.png'
import tuumaMobileOff from '../../../assets/ui/tuuma-mobile-off.png'
import auth from '../../../kea/auth'
import HTML from '../util/HTML'
import rmLogo from '../../../assets/logos/rm-logo.png'

const TRANSITION = {
  IN: 'transitionIn',
  OUT: 'transitionOut',
}

@connect({
  actions: [
    application, [
      'setViewData',
    ]
  ],
  props: [
    auth, [
      'loggedIn',
    ]
  ]
})
class SearchMenu extends Component {
  constructor (props) {
    super(props)

    this.resultNodes = []

    this.state = {
      open: false,
      searchString: '',
      style: TRANSITION.OUT,
      searchResults: [],
      tuuma: false
    }
  }

  static propTypes = {
    open: PropTypes.bool,
    closeMenu: PropTypes.func,
    history: PropTypes.object,
    transitionInStyle: PropTypes.object,
    transitionOutStyle: PropTypes.object,
    actions: PropTypes.object,
    loggedIn: PropTypes.bool,
  }

  componentDidMount () {
    this.updateData(this.props)
    if (this.searchInput) this.searchInput.focus()
  }

  componentWillUnmount () {
    clearTimeout(this.transitionAction)
  }

  componentWillReceiveProps (nextProps) {
    this.updateData(nextProps)
  }

  updateData = (props) => {
    if (props.open) {
      this.setState({
        open: true,
      })

      if (this.searchInput) {
        this.searchInput.focus()
      }

      if (this.transitionAction) clearTimeout(this.transitionAction)
      this.transitionAction = setTimeout(this.transitionIn, 10)
    } else {
      this.transitionOut()
    }
  }

  transitionIn = () => {
    this.setState({
      style: TRANSITION.IN,
    })
  }

  transitionOut = () => {
    this.setState({
      style: TRANSITION.OUT,
      searchResults: [],
      showAutocomplete: false
    })
  }

  closeMenu = () => {
    this.props.closeMenu()
    this.setState({
      searchResults: [],
      showAutocomplete: false,
    })
  }

  handleInput = (event) => {
    this.setState({
      searchString: event.currentTarget.value,
    }, this.showAutocomplete.bind(this))
  }

  doSearch = () => {
    if (this.state.tuuma) {
      if (this.props.loggedIn) {
        this.props.history.push('/tuuma?question=' + encodeURIComponent(this.state.searchString))
      }
    } else {
      this.props.history.push('/haku?searchString=' + encodeURIComponent(this.state.searchString))
      this.props.actions.setViewData({ _meta: 'search' })
      this.props.closeMenu()
      this.setState({
        searchResults: [],
        showAutocomplete: false,
      })
    }
  }

  searchOnEnter = (e) => {
    if (e.key === 'Enter') {
      this.doSearch()
    } else if (e.keyCode === 27) {
      this.props.closeMenu()
      this.setState({
        searchResults: [],
        showAutocomplete: false,
      })
    }
  }

  handleInputKeyDown = (e) => {
    const { searchResults } = this.state
    if ((e.key === 'ArrowDown')) {
      e.preventDefault()
      if (searchResults.length <= 0) return
      this.resultNodes[0].children[0].focus()
    }
  }

  handleItemKeyDown = (e) => {
    const { searchResults } = this.state
    if ((e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
      e.preventDefault()
      if (searchResults.length <= 0) return

      const active = document.activeElement.parentNode
      const next = e.key === 'ArrowDown' ? active.nextSibling : active.previousSibling
      if (next) {
        next.children[0].focus()
      } else {
        if (e.key === 'ArrowUp') {
          this.searchInput.focus()
        }
      }
    }
  }

  async showAutocomplete () {
    if (this.state.searchString && this.state.searchString.length >= 3) {
      const { tm, rm } = await WP.elasticSearch(
        this.state.searchString,
        'BEST_MATCH',
        {
          publishedAfter: '1953-01-01T00:00:00.000+02:00',
          publishedBefore: '2053-12-31T23:59:59.999+02:00',
          include: 'ALL'
        },
        1,
        9,
        null)
      this.setState({
        searchResults: tm && (rm && rm.hits ? tm.hits.concat(rm.hits.slice(0,1)) : tm.hits),
        showAutocomplete: true,
      })
    } else {
      this.setState({
        searchResults: [],
        showAutocomplete: false,
      })
    }
  }

  transitionEnd = (e) => {
    if (e.target !== e.currentTarget) return // ignore transitions in children

    if (this.props.open) {
      this.searchInput.focus()
    } else {
      this.setState({
        open: false
      })
    }
  }

  replace (url) {
    if (url.includes('/digilehti') || url.includes('/nakoislehti')) {
      return url
    }
    return url.replace(/https:\/\/wp\.tekniikanmaailma\.fi/,'')
  }

  render () {
    const {
      transitionInStyle,
      transitionOutStyle,
      loggedIn
    } = this.props
    const { tuuma } = this.state

    const containerStyles = classnames('search-container', this.state.style, {
      open: this.state.open,
    })

    const transitionStyle = this.state.style === TRANSITION.IN ? transitionInStyle : transitionOutStyle

    return this.state.open && (
      <div onTransitionEnd={this.transitionEnd} styleName={containerStyles} style={transitionStyle}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div styleName={this.state.searchString ? 'menu with-scroller active' : 'menu with-scroller'} onKeyDown={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchMove={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}>
          <Fragment>
            <div styleName="input-row">
              <div styleName="tuuma-button">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img styleName="mobile" src={tuuma ? tuumaMobileOn : tuumaMobileOff} onClick={() => this.setState({ tuuma: !tuuma })} alt="Käytä Tuumaa"/>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img styleName="not-mobile" src={tuuma ? tuumaOn : tuumaOff} onClick={() => this.setState({ tuuma: !tuuma })} alt="Käytä Tuumaa"/>
                {tuuma && !loggedIn
                  ? <div styleName="premium-popup">
                    <div>Tuuma-tekoälyhaku etsii vastauksen kysymykseesi TM:n jutuista. Kysy siltä mitä vain!</div>
                    <p>Tilaajana voit käyttää Tuumaa.</p>
                    <Link to={{ link: '/tilaa/' }} onClick={(e) => {
                      let promoPosition = '-single'
                      if (window.location.pathname === '/') {
                        promoPosition = '-etusivu'
                      } else if (window.location.pathname.includes('/kategoria/') || window.location.pathname.includes('/avainsana/')) {
                        promoPosition = '-kategoria'
                      }
                      window.location.href = 'https://tilaus.tekniikanmaailma.fi/tilaus?promoID=OM_tilaa-painike&promoName=OM_TM&promoCreative=onlinetarjous&promoPosition=header' + promoPosition
                      e.preventDefault()
                      return false
                    }}
                    styleName="order-button">Tilaa</Link>
                    <div styleName="caret"></div>
                  </div>
                  : null}
              </div>

              <input
                ref={(input) => { this.searchInput = input }}
                onChange={this.handleInput}
                onKeyUp={this.searchOnEnter}
                onKeyDown={this.handleInputKeyDown}
              />
              <button styleName="search-button" onClick={this.doSearch}>
                <SearchIconRed />
              </button>
              <div aria-hidden="true" styleName="scroller scroll-1"><p>Kirjoita tähän, mitä olet etsimässä</p></div>
              <div aria-hidden="true" styleName="scroller scroll-2"><p>Kokeile kirjoittaa esimerkiksi auton merkki ja malli</p></div>
              <div aria-hidden="true" styleName="scroller scroll-3"><p>Voit etsiä tietoa elektroniikkatesteistämme</p></div>
              <div aria-hidden="true" styleName="scroller scroll-4"><p>Kirjoita esimerkiksi Peugeot 308 tai Samsung Galaxy</p></div>
              <div aria-hidden="true" styleName="scroller scroll-5"><p>Hae tietoa TM:n näköislehtiarkistosta</p></div>
              <div aria-hidden="true" styleName="scroller scroll-6"><p>Kokeile myös tekoälyhakuamme tm.fi/tuuma</p></div>
              <div aria-hidden="true" styleName="scroller scroll-7"><p>Kirjoita tähän, mitä olet etsimässä</p></div>
            </div>
            <div styleName="close-button">
              <button onClick={this.closeMenu}>
                <span styleName="close-icon">
                  <span aria-hidden="true">+</span>
                </span>Sulje haku
              </button>
            </div>
            {!tuuma && this.state.showAutocomplete && this.state.searchResults && <ul styleName="search-autocomplete">
              {this.state.searchResults.map((result, idx) => {
                const isRM = result.brand === "Rakennusmaailma"
                const title =
                  (result.customValues.issue_name ? result.customValues.issue_name + ', ' : '') + // eslint-disable-next-line eqeqeq
                    (result.title == parseInt(result.title)
                      ? 'sivu ' + result.title
                      : result.title
                    ) +
                  ' (' + format(new Date(result.published), 'DD.MM.YYYY') + ')'
                return (
                  <li
                    styleName={isRM ? 'rm' : null}
                    key={idx}
                    ref={(ref) => { this.resultNodes[idx] = ref }}
                  >
                    <Link
                      to={{ link: this.replace(result.url) }}
                      localContext="SearchMenu"
                      onClick={this.closeMenu}
                      onKeyDown={this.handleItemKeyDown}
                    >
                      {isRM ? <div styleName="rm-title">Lisää aiheesta TM Rakennusmaailmassa – Tilaa, jos et ole jo tilaaja!</div>: null}
                      <span>{isRM ? <img src={rmLogo} /> : null}<HTML>{title}</HTML></span>
                    </Link>
                  </li>
                )
              })}
            </ul>}
          </Fragment>
        </div>
      </div>
    )
  }
}

export default track({ gtmContext: ['SearchMenu'] })(withErrorBoundary(
  withRouter(SearchMenu),
  ErrorPlaceholder(null)
))
