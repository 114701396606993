import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'

import magazineStore from '../../kea/weeklyMagazine'
import auth from '../../kea/auth'
import HTML from '../general/util/HTML'
import ProductAds from '../general/article/ProductAds'
import ArticleAuthorList from '../general/article/ArticleAuthorList'
import ArticleAuthorListV2 from '../general/article/ArticleAuthorListV2'
import Comparisons from '../general/article/Comparisons'
import Image from '../../components/general/util/Image'
import ShareButtons from '../general/widgets/ShareButtons'
import KeywordList from '../general/lists/KeywordList'
import SideStories from '../general/article/SideStories'
import WeightTable from '../general/article/WeightTable'
import Accordion from '../general/article/Accordion'
import articleTypes from '../general/article/ArticleTypes'
import RelatedArticleList from '../general/article/RelatedArticleList'
import ArticleComments from '../general/comments/ArticleComments'
import Paywall from '../widgets/Paywall'
import PaywallBanner from '../widgets/PaywallBanner'
import MostReadPaid from '../widgets/MostReadPaid'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import { IMAGE_SIZE } from '../../entities/ImageModel'
import { AdsForDesktop, AdsForMobile, AdSlotDesk1, AdSlotDesk2, AdSlotMob1, AdSlotMob2 } from '../general/ads/Ads'
import { SubscriberIcon } from '../widgets/Icons'
import WP from '../../lib/WP'
import { getURL } from '../../lib/WPClient'
import some from 'lodash/some'
import PrevNextBox from '../widgets/PrevNextBox'
import DigimagBox from '../widgets/DigimagBox'
import TestBox from '../widgets/TestBox'
import Timeline from '../widgets/Timeline'
import { enableScrollAnimations, disableScrollAnimations } from '../../lib/utils'
import PopularPosts from '../general/popular/PopularPosts'
import NettixEmbed from '../general/nettix/NettixEmbed'
import CategoryList from '../general/lists/CategoryList'
import ArticleMetaDivider from '../general/article/ArticleMetaDivider'
import ArticleDate from '../general/article/ArticleDate'
import CrossLinkTopNotification from '../widgets/CrossLinkTopNotification'
import CrossLinkBottomNotification from '../widgets/CrossLinkBottomNotification'
import ProductMemory from '../general/article/ProductMemory'
import ProductCard from '../general/article/ProductCard'
import Question from '../general/article/Question'
import tmLogo from '../../assets/logos/tm-logo.svg'
import Themebox from '../general/article/Themebox'
import GAMNativeAdHolder from '../widgets/GAMNativeAdHolder'
import { ShowAll } from '../widgets/ShowAll'
import AuthorWidget from '../widgets/AuthorWidget'
import ReportError from '../general/widgets/ReportError'
import AiArticleWidget from '../general/widgets/AiArticleWidget'

import './FeatureArticle.scss'

@connect({
  props: [
    magazineStore, [
      'getCurrentArticleTitle',
    ],
    auth, [
      'premiumUser',
      'shareTokenAccess'
    ]
  ]
})
  /**
   * This renders a feature article (Digilehden artikkelin Feature-leiska), which is mostly the same as a
   * MagazineArticle, but with slightly different layout. It renders all content on the page between the top row ad
   * and the footer. These articles don't have a sidebar.
   *
   * The Article component renders this component.
   */

class FeatureArticle extends Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
    pagination: PropTypes.object,
    payWall: PropTypes.bool,
    getCurrentArticleTitle: PropTypes.string,
    noManualComparisonPlacement: PropTypes.bool,
    premiumUser: PropTypes.bool,
    previousArticle: PropTypes.object,
    nextArticle: PropTypes.object,
    shareTokenAccess: PropTypes.func,
    doneLoading: PropTypes.func,
  }

  prevScrollPos = 0

  constructor () {
    super()
    this.state = { nextArticle: {}, previousArticle: {}, articlesLoaded: false, scrolling: false }
  }

  async loadPrevNextArticles (props, state) {
    const prev = await this.maybeLoadArticle('previousArticle', props, state)
    const next = await this.maybeLoadArticle('nextArticle', props, state)
    const newState = { ...prev, ...next }
    if (Object.keys(newState).length) {
      this.setState(newState)
    }
  }

  async maybeLoadArticle (propName, props, state) {
    const newState = {}
    if (props[propName] && props[propName].link && !state.articlesLoaded) {
      if (!props[propName].image) {
        // no image, load article
        newState[propName] = (await WP.getForURL(getURL(props[propName].link))).data
      } else {
        newState[propName] = props[propName]
      }
      newState.articlesLoaded = true
    }
    return newState
  }

  async componentDidMount () {
    this.loadPrevNextArticles(this.props, this.state, false)
    window.addEventListener('scroll', this.handleScroll)
    enableScrollAnimations('.alignnone > div:first-child, .FeatureArticle_header-image-container img, .alignnone p:not(.wp-caption-text), .FeatureArticle_header-image-container video')
  }

  componentWillUnmount () {
    disableScrollAnimations()
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevState.articlesLoaded) {
      this.loadPrevNextArticles(this.props, this.state)
    }
  }

  renderVignetteAndTopic = article => {
    if (article.vignette && article.topic) {
      return (
        <Fragment>
          <HTML>{article.vignette}</HTML>: <HTML>{article.topic}</HTML>
        </Fragment>
      )
    } else if (article.vignette) {
      return <HTML>{article.vignette}</HTML>
    } else {
      return null
    }
  }

  renderHeaderImage = (video, featuredMedia, mobileHero, scrolling, titleColour, extraVideo) => {
    let style = 'header-image-container'
    let mobileStyle = 'header-image-container mobile'
    if (scrolling && !extraVideo) {
      style = style + ' filter'
      mobileStyle = mobileStyle + ' filter'
    }
    if (mobileHero) {
      style = style + ' not-mobile'
    }
    if (titleColour) {
      if (Number(titleColour.replace('#', '0x')) < 0x808080) {
        // if title colour is dark, lighten background. Else darken it.
        style = style + ' lighten'
        mobileStyle = mobileStyle + ' lighten'
      }
    }
    return video
      ? <Fragment>
        <div styleName={style}>
          <div dangerouslySetInnerHTML={{
            __html: `
            <video
              loop
              muted
              autoplay
              playsinline
              src="${video.url}"/>`
          }}>
          </div>
        </div>
        {mobileHero
          ? <div styleName={mobileStyle}>
            <Image addToGallery data={mobileHero} isZoomed/>
          </div>
          : null}
      </Fragment>
      : <Fragment>
        <div styleName={style}>
          <Image addToGallery data={featuredMedia} isZoomed/>
        </div>
        {mobileHero
          ? <div styleName={mobileStyle}>
            <Image addToGallery data={mobileHero} isZoomed/>
          </div>
          : null}
      </Fragment>
  }

  renderMainImage = (video, featuredMedia = {}) => {
    if (video) {
      return (
        <div styleName="main-image-container" dangerouslySetInnerHTML={{
          __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            src="${video.url}"
          />`
        }}>
        </div>
      )
    }

    const { copyright, caption } = featuredMedia
    return (
      <div styleName="main-image-container">
        <Image addToGallery data={featuredMedia} sizes={'(max-width: 768px) 100vw, (max-width: 1024px) 75vw, 1024px'}
          size={IMAGE_SIZE.LARGE} noHeight={true}/>

        {(caption || copyright) && (
          <div styleName="caption-box">
            <figcaption><HTML>{caption}</HTML></figcaption>
            <div styleName="image-copyright"><HTML>{copyright}</HTML></div>
          </div>
        )}
      </div>
    )
  }

  renderHeader = (article, title, video) => {
    // const isBest = some(article.categories, {slug: 'testivoittaja'})
    const isResearcher = some(article.categories, (cat) => cat.slug === 'tutkijalta')

    return <Fragment>
      <header styleName="header">
        {this.renderHeaderImage(video, article.featuredMedia, article.headerVideoMobile || article.mobileHero)}
      </header>
      <div styleName="title-area">
        <div styleName={article.whiteBackground ? 'background background-white' : 'background'}>
          {/*
          <div styleName={isBest ? 'category-row below-best-icon' : 'category-row'}>
            <div styleName="article-categories">
              {(article.categories && article.categories.length) && !article.isAd
                ? (<Fragment><CategoryList categories={article.categories} mainOnly /><ArticleMetaDivider /></Fragment>)
                : null}
              <ArticleDate date={article.createdDate} dateOnly/>
            </div>
          </div>
          */}
          {article.vignette
            ? (
              <div styleName="vignette">
                {this.renderVignetteAndTopic(article)}
              </div>
            )
            : null
          }
          <h1 styleName={article.capitalizeTitle ? 'capitalized' : ''}><HTML>{title}</HTML></h1>
          {article.ingress
            ? <div styleName="ingress"><div>{isResearcher &&
          <span styleName="researcher">Tutkijalta</span>}<HTML>{article.ingress}</HTML></div></div>
            : null}
        </div>
      </div>
    </Fragment>
  }

  renderV2Header = (article, title, video) => {
    const isResearcher = some(article.categories, (cat) => cat.slug === 'tutkijalta')
    let mainTitleClass = ''
    let vignetteClass = 'vignette red'
    if (article.extraTitleColour) {
      if (Number(article.extraTitleColour.replace('#', '0x')) < 0x808080) {
        // if image title colour is dark, use red main title
        mainTitleClass = 'red'
        vignetteClass = 'vignette'
      }
    }

    return <Fragment>
      <header
        styleName={(article.extraVideo && this.state.scrolling ? 'header-v2 zoom' : 'header-v2') + (article.extraVideo ? ' extra-video' : '')}>
        {this.renderHeaderImage(video, article.featuredMedia, article.headerVideoMobile || article.mobileHero, this.state.scrolling, article.extraTitleColour, article.extraVideo)}
      </header>
      <div styleName="fixed-text">
        {!!article.runningHead2 && <div styleName="image-holder">
          <div styleName="img">
            <img src={tmLogo} alt=""/>
          </div>
          <div styleName="text">
            <HTML>{article.runningHead2}</HTML>
          </div>
        </div>}
        <div styleName="title-holder" style={{ color: article.extraTitleColour }}>
          <HTML>{article.extraTitle}</HTML>
        </div>
      </div>
      {article.extraVideo
        ? <Image addToGallery data={article.extraVideo} divStyle="secondary-video" isZoomed/>
        : null}
      <div styleName="title-area-v2">
        {article.vignette
          ? (
            <div styleName={vignetteClass}>
              {this.renderVignetteAndTopic(article)}
            </div>
          )
          : null
        }
        {article.extraVideo || article.extraTitleOnly ? null : <h1 styleName={article.capitalizeTitle ? 'capitalized ' + mainTitleClass : mainTitleClass}><HTML>{title}</HTML></h1>}
        {article.ingress
          ? <div styleName="ingress"><div styleName="ingress-shadow"></div><div>{isResearcher &&
          <span styleName="researcher">Tutkijalta</span>}<HTML>{article.ingress}</HTML></div></div>
          : null}
      </div>
    </Fragment>
  }

  renderCommentAuthor = article => {
    const { featuredMedia, commentator, authorTitle } = article

    return (
      <div styleName="comment-author">
        {featuredMedia && <figure>
          <div styleName="featured-image">
            <Image data={featuredMedia} size={IMAGE_SIZE.MEDIUM_LARGE} sizes={'(min-width: 576px) 250px, 100vw'} isZoomed/>
          </div>
        </figure>
        }
        <div styleName="comment-author-description">
          <ArticleAuthorList author={commentator} authorTitle={authorTitle}/>
        </div>
      </div>
    )
  }

  renderIngressArea (article) {
    return <div styleName="ingress-area">
      {article.forSubscribers && <div styleName="subscriber-icon"><SubscriberIcon/></div>}
      <div styleName="category-row">
        {article.articleType !== articleTypes.MAGAZINE_COMMENT
          ? <div styleName="article-categories">
            {(article.categories && article.categories.length) && !article.isAd
              ? (<Fragment><CategoryList categories={article.categories} mainOnly/><ArticleMetaDivider/></Fragment>)
              : null}
            <ArticleDate date={article.createdDate} dateOnly/>
          </div>
          : null
        }
      </div>
      <div styleName="article-meta-row">
        {article.articleType !== articleTypes.MAGAZINE_COMMENT
          ? (
            <div styleName="article-author">
              {article.articleType === articleTypes.FEATUREV2
                ? <ArticleAuthorListV2 author={article.author} photographer={article.photographer}
                  assistants={article.assistants} authorTitle={article.authorTitle}/>
                : <ArticleAuthorList author={article.author} photographer={article.photographer}
                  assistants={article.assistants} authorTitle={article.authorTitle}/>
              }
            </div>
          )
          : null
        }
      </div>
      <div styleName="meta-row-share-buttons">
        <ShareButtons shareCount={article.shareCount} article={article}/>
      </div>
    </div>
  }

  handleScroll = () => {
    const doc = document.documentElement
    const position = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)

    if (this.prevScrollPos <= 0 && position > 0) {
      this.setState({ scrolling: true })
      this.prevScrollPos = position
    } else if (this.prevScrollPos > 0 && position <= 0) {
      this.setState({ scrolling: false })
      this.prevScrollPos = 0
    }
  }

  render () {
    const {
      article,
      payWall,
      getCurrentArticleTitle,
      noManualComparisonPlacement,
      premiumUser,
      shareTokenAccess,
    } = this.props

    const {
      id,
      content,
      pagination,
      headerVideoMp4,
      headerVideoWebm,
      comparisons,
      sidestories,
      tags,
      weightTable,
      details,
      title,
      titleOverride,
      linkedProductCards,
      linkedHistoryCards,
      linkedProductMemories,
      linkedQuestions,
      createToc,
      relatedArticles,
      productAds,
      relatedAds,
      hideAds,
      disableAdCoin,
      isAd,
      timelineTag,
      timelineDigimag,
      nettixSearchId,
      categories,
      originalArticle,
      articleType,
      scrollVideos,
      author
    } = article

    const articleStyles = ['article', 'article-container']
    const isBest = some(article.categories, { slug: 'testivoittaja' })
    const isTest = some(article.tags, { slug: 'testipankki' })

    if (article.articleType !== articleTypes.MAGAZINE_COMMENT) articleStyles.push('special')
    if (isAd) articleStyles.push('is-ad')

    const textStyles = `article-text ${payWall ? 'paywall-fade' : ''}`

    const shownTitle = ((article.printmag || article.omAdMagazine || article.omThemeMagazine)
      ? (getCurrentArticleTitle === ''
        ? (titleOverride || title)
        : getCurrentArticleTitle)
      : (titleOverride || title))

    let ads = []
    if (payWall && linkedProductCards && linkedProductCards.length) {
      linkedProductCards.forEach((productCard) => {
        if (productCard.productAds && productCard.productAds.length) {
          ads = ads.concat(productCard.productAds)
        }
      })
    }

    return (
      <Fragment>
        <article styleName={articleStyles.join(' ')} id="magazine-article">
          {articleType === articleTypes.FEATUREV2
            ? this.renderV2Header(article, shownTitle, headerVideoMp4 || headerVideoWebm)
            : this.renderHeader(article, shownTitle, headerVideoMp4 || headerVideoWebm)
          }
          <div styleName="white-background">
            <div>
              {this.renderIngressArea(article)}
              {originalArticle &&
                <div styleName="margins">
                  <CrossLinkTopNotification text={originalArticle.topMessage}/>
                </div>
              }
              <div styleName={'body-container'}>
                <div styleName={'center-col'}>
                  <PaywallBanner/>
                </div>
                {shareTokenAccess(article) && !premiumUser ? <div>
                  <AdsForDesktop>
                    <AdSlotDesk1 loadInstantly={true} setTargeting={true}/>
                  </AdsForDesktop>
                  <AdsForMobile>
                    <AdSlotMob1 loadInstantly={true} setTargeting={true}/>
                  </AdsForMobile>
                </div> : null}
                {!payWall && noManualComparisonPlacement && comparisons && comparisons.length === 1
                  ? (
                    <div styleName="comparison-container comparison-container-right">
                      <Comparisons data={comparisons} floated={true} short={payWall}/>
                    </div>
                  )
                  : null
                }
                <div styleName={`${textStyles}`}
                  className={'article-body ' + (premiumUser ? 'user-level-4' : '') + (hideAds ? ' noAds' : '')}>
                  {payWall
                    ? <HTML options={{ pagination, filterDivs: true }}>{content}</HTML>
                    : <HTML options={{
                      linkedProductCards,
                      linkedHistoryCards,
                      linkedProductMemories,
                      scrollVideos,
                      goodbad: comparisons,
                      id,
                      pagination,
                      createToc
                    }}>{content}</HTML>
                  }
                </div>
                {payWall
                  ? <div styleName={'center-col'}>
                    <Paywall disableAdCoin={disableAdCoin}/>
                  </div>
                  : null}
                {/* payWall && linkedProductCards && linkedProductCards.length
                  ? (
                    <div styleName="comparison-container">
                      <Comparisons data={linkedProductCards.map(card => card.productData)} short={payWall}/>
                    </div>
                  )
                  : null
                */}
                {!payWall && noManualComparisonPlacement && comparisons && comparisons.length > 1
                  ? (
                    <div styleName="comparison-container">
                      <Comparisons data={comparisons} short={payWall}/>
                    </div>
                  )
                  : null
                }
                {weightTable ? weightTable.map((table, idx) => <WeightTable key={idx} data={table}/>) : null}
                {details && !!details.length && (
                  <div styleName="details-wrapper">
                    <Accordion details={details}/>
                  </div>
                )}
                <SideStories sidestories={sidestories} margins/>
                {!article.forSubscribers || premiumUser
                  ? <div styleName="margins">
                    {linkedProductMemories.map((card, key) => <div styleName="" key={key}><ProductMemory card={card}/>
                    </div>)}
                    {linkedProductCards.map((card, key) => <div styleName="" key={key}><ProductCard card={card}/></div>)}
                    {linkedQuestions.map((card, key) => <div styleName="" key={key}><Question card={card}/></div>)}
                  </div>
                  : null}
                
                  <div styleName="margins">
                    <AuthorWidget author={author}/>
                  </div>
                
                {(productAds || relatedAds) &&
                  <div styleName="margins"><ProductAds ads={productAds} related={relatedAds}/></div>}
                {!!ads.length && <div styleName="center-col" style={{ marginTop: '1rem' }}><ProductAds ads={ads}/></div>}
                {originalArticle &&
                  <div styleName="margins">
                    <CrossLinkBottomNotification title={originalArticle.title} text={originalArticle.siteDescription}
                      linkText={originalArticle.linkText} href={originalArticle.url}/>
                  </div>
                }
                {isTest
                  ? <div styleName="margins how-we-test">
                    <p><b>Näin Tekniikan Maailma testaa.</b> Teemme testejämme, vertailujamme sekä koeajojamme puolueettomasti ja puhtaasti journalistisin periaattein.</p>
                    <ShowAll linkText="Lue lisää testimenetelmistämme ja periaatteistamme täältä." url="/nain-tekniikan-maailma-testaa-autoja-renkaita-ja-elektroniikkaa/"/>
                  </div>
                  : null}
                {article.linkedThemeBoxesData && (
                  <div styleName="margins">
                    {article.linkedThemeBoxesData.map((tbData, key) => <Themebox key={key} data={tbData}></Themebox>)}
                  </div>
                )}
                <div styleName="meta-row-share-buttons-bottom">
                  <ShareButtons shareCount={article.shareCount} article={article} up/>
                  {isAd ? null : <ReportError article={article}/>}
                </div>
                {relatedArticles && relatedArticles.length > 0 && (
                  <div styleName={'also-read margins'}>
                    <h2>Lue myös</h2>
                    <Fragment>
                      <RelatedArticleList related={relatedArticles} limit={4}/>
                    </Fragment>
                  </div>
                )}
                <div styleName={'center-col'}>
                  <PaywallBanner showText={true}/>
                </div>
                {shareTokenAccess && !premiumUser ? <div>
                  <AdsForDesktop>
                    <AdSlotDesk2 loadInstantly={true} setTargeting={true}/>
                  </AdsForDesktop>
                  <AdsForMobile>
                    <AdSlotMob2 loadInstantly={true} setTargeting={true}/>
                  </AdsForMobile>
                </div> : null}
                {article.forSubscribers && !premiumUser
                  ? <Fragment>
                    <div styleName="bottom-navigation">
                      <PrevNextBox article={article}/>
                      <DigimagBox article={article}/>
                    </div>
                    <div styleName={'center-col'}><MostReadPaid exclude={[id]}
                      doneLoading={() => this.props.doneLoading('mostRead')}/>
                    </div>
                  </Fragment>
                  : null}
                {tags && tags.length
                    ? (
                      <div styleName={'keywords margins'}>
                        <span styleName="title">Lisää aiheesta</span>
                        <Fragment>
                          <KeywordList keywords={tags}/>
                        </Fragment>
                      </div>
                    )
                    : null
                }
                <div styleName="margins">
                  <AiArticleWidget article={article}/>
                </div>
                <div styleName="red-line margins"></div>
                {nettixSearchId
                  ? (
                    <div styleName="nettix-embed-container bottom-navigation">
                      <NettixEmbed searchId={nettixSearchId}/>
                    </div>
                  )
                  : null}
              </div>
              <div styleName="almost-full">
                {timelineTag
                  ? <Timeline exclude={[article.id]} term={timelineTag} digimagOnly={timelineDigimag}
                    doneLoading={() => this.props.doneLoading('timeline')}/>
                  : null}
              </div>
            </div>
          </div>
        </article>
        <div styleName="white-background bottom">
          <div>
            {isBest || isTest ? <div styleName="margins"><TestBox/></div> : null}
            <div styleName={articleStyles.join(' ')}>
              {!hideAds
                ? <div>
                  <AdsForDesktop>
                    <AdSlotDesk1 loadInstantly={true}/>
                  </AdsForDesktop>
                  <AdsForMobile>
                    <AdSlotMob1 loadInstantly={true}/>
                  </AdsForMobile>
                  <GAMNativeAdHolder grow={true} />
                </div>
                : null}
              {!payWall && (!shareTokenAccess(article) || premiumUser)
                ? <div styleName="bottom-navigation">
                  <PrevNextBox article={article}/>
                  <DigimagBox article={article}/>
                </div>
                : null
              }
              <div styleName="margins top-margin"><PopularPosts doneLoading={() => {}}/></div>
              
                <div styleName="margins top-margin"><PopularPosts paidOnly doneLoading={() => {
                }}/></div>
              
            </div>
            {!isAd && <div styleName="comment-container margins">
              <ArticleComments articleId={id}/>
            </div>}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withErrorBoundary(
  FeatureArticle,
  ErrorPlaceholder()
)
